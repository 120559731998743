<template>
  <v-dialog v-model="mostrar" persistent width="750">
    <v-card :loading="loading">
      <v-toolbar flat dense dark color="primary">
        <v-toolbar-title>Crear sección</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="7" align-self="center">
            <v-text-field
              v-model="nombre"
              filled
              dense
              label="Nombre"
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <div class="d-flex align-center">
              <v-select
                v-model="tiempo"
                outlined
                label="Tiempo"
                :disabled="loading"
                :items="tiempos"
              >
                <template v-slot:selection="{ item }">
                  <span> {{ item }} minutos</span>
                </template>
                <template v-slot:item="{ item }">
                  <span>{{ item }} minutos</span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12">
            <span class="text-subtitle-1 text--secondary"
              >Descripción de la sección:</span
            >
            <editor-texto
              ref="editorTexto"
              :texto="descripcion"
              app="empresa"
              contenido="examen"
              :idContenido="$route.params.idExamen"
            ></editor-texto>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          :disabled="loading || !esValido"
          @click="submit()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { crearSeccion, modificarSeccion } from "./examenes.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import editorTexto from "@/components/globals/template/contenidos/editorTexto";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    seccion: { type: Object, default: () => null },
  },

  components: {
    "editor-texto": editorTexto,
  },

  data: () => ({
    loading: false,
    tiempos: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    nombre: "",
    descripcion: "",
    tiempo: 5,
  }),

  computed: {
    errors() {
      const errors = {
        nombre: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombre.length) return false;
      return true;
    },
  },

  watch: {
    seccion() {
      this.setCurrentSeccion();
    },
  },

  validations: {
    nombre: { required },
  },

  mounted() {
    this.setCurrentSeccion();
  },

  methods: {
    setCurrentSeccion() {
      if (this.seccion) {
        this.nombre = this.seccion.nombre;
        this.descripcion = this.seccion.descripcion;
        this.tiempo = this.seccion.tiempo;
      }
    },

    async submit() {
      this.$v.nombre.$touch();
      if (!this.esValido) return;

      this.loading = true;
      try {
        const { idExamen } = this.$route.params;
        const requestData = {
          nombre: this.nombre,
          descripcion: this.$refs.editorTexto.obtenerTexto(),
          tiempo: this.tiempo,
          examen: this.$route.params.idExamen,
        };
        if (this.seccion) delete requestData.examen;

        const serverResponse = this.seccion
          ? await modificarSeccion({
              idSeccion: this.seccion._id,
              data: requestData,
              idExamen,
            })
          : await crearSeccion(requestData, idExamen);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("seccionGuardada", serverResponse.seccion);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
