import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"750"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"flat":"","dense":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Crear sección")])],1),_c(VCardText,{staticClass:"pt-3 pb-0 px-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"7","align-self":"center"}},[_c(VTextField,{attrs:{"filled":"","dense":"","label":"Nombre","disabled":_vm.loading,"error-messages":_vm.errors.nombre},on:{"input":function($event){return _vm.$v.nombre.$touch()},"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c(VCol,{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{attrs:{"outlined":"","label":"Tiempo","disabled":_vm.loading,"items":_vm.tiempos},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item)+" minutos")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item)+" minutos")])]}}]),model:{value:(_vm.tiempo),callback:function ($$v) {_vm.tiempo=$$v},expression:"tiempo"}})],1)]),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary"},[_vm._v("Descripción de la sección:")]),_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcion,"app":"empresa","contenido":"examen","idContenido":_vm.$route.params.idExamen}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading || !_vm.esValido},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }